<template>
  <v-sheet color="transparent" :width="width || null" >
    <h5 v-if="label" style="color : #4F5A6E; font-size: 13px; font-weight: 500 " class="mb-2">  {{  label  }} </h5>
    <h5 v-if="description" style="font-size: 13px; font-weight: 400 " class="mb-2">  {{  description  }} </h5>
    <v-sheet width="100%" class="wsRoundedLight d-flex" >
      <v-btn
          @click="toggle = true"
          :outlined="toggle !== true"
          :dark="toggle === true"
          :color="wsACCENT"
          class="leftButton noCaps"
          elevation="0"

          width="50%"  >
        {{  textTrue  }}
      </v-btn>
      <v-btn
          @click="toggle = false"
          :outlined="toggle !==false"
          :dark="toggle ===false"
          :color="wsACCENT"
          class="rightButton noCaps"
          elevation="0"

          width="50%"
      >
        {{ textFalse }}
      </v-btn>
    </v-sheet>
  </v-sheet>

</template>

<script>
export default {
  name: "wsToggle",
  props : {
    value : {
      type : Boolean,
    },
    label : {
      type : String,
      default : ''
    },
    description : {
      type : String,
      default : ''
    },
    textTrue : {
      type : String,
      default : ''
    },
    textFalse : {
      type : String,
      default : ''
    },
    width : {
      default : null
    }
  },
  watch : {
    value() {
      if ( this.value !== this.toggle ) {
        this.toggle = this.value
      }
    },
    toggle() {
      if (this.value !== this.toggle ) {
        this.$emit('input',this.toggle)
      }
    }
  },
  data() {
    return {
      toggle : null
    }
  },
  mounted() {
    if ( this.value === false || this.value === true ) {
      this.toggle = this.value
    }
  }
}
</script>

<style scoped>
.leftButton {
  border-radius: 4px 0 0 4px;
  border-right: unset;
  font-weight: 500;
  font-size: 13px;
}
.rightButton {
  border-radius: 0 4px 4px 0;
  font-weight: 500;
  font-size: 13px;
}
</style>